import axios, { AxiosRequestConfig } from 'axios';
import TokenService from '@/api/auth/token';
import jwt_decode from 'jwt-decode';

const token = localStorage.getItem('token');

const loginConfig: AxiosRequestConfig = {
  baseURL: process.env.VUE_APP_BASE_AUTH,
  headers: {
    'Content-Type': 'application/json',
  },
};

const LoginAPI = axios.create({
  baseURL: process.env.VUE_APP_BASE_AUTH,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

LoginAPI.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;
    const status = error.response.status;
    if (status === 401) {
      if (!error.config._retry) {
        originalConfig._retry = true;
        try {
          const nt = await TokenService.refreshToken();
          originalConfig.headers.Authorization = `Bearer ${nt.token}`;
          return LoginAPI(originalConfig);
        } catch (_error: any) {
          console.warn(_error);
          originalConfig._retry = true;
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }
          return Promise.reject(_error);
        }
      } else {
        console.warn('401 error');
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        location.replace(`${location.origin}/login`);
      }
    }
    return Promise.reject(error);
  }
);
// admin api
const defaultConfig: AxiosRequestConfig = {
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
};

const DefaultAPI = axios.create(defaultConfig);

DefaultAPI.defaults.headers.common['Authorization'] = `Bearer ${token}`;

// Interceptors

DefaultAPI.interceptors.request.use(async (req) => {
  // getting token & refresh tokens
  const rt = localStorage.getItem('refresh_token');
  let t = localStorage.getItem('token');
  const currentContentLanguage = localStorage.getItem(
    `language-onskin-admin_${process.env.VUE_APP_MODE}`
  );

  // check if token & refresh tokens are valid
  if (!t || !rt) {
    return window.location.replace('/login');
  }
  // check refresh token for date & time expiration
  const { exp: refreshExp } = jwt_decode<any>(`${rt}`);
  const isRefreshExpired = Date.now() >= refreshExp * 1000;
  if (isRefreshExpired) {
    return window.location.replace('/login');
  }

  if (req && req.headers) {
    req.headers['Accept-Language'] = currentContentLanguage || 'en';
  }

  // getting access token expiration date & time
  const { exp } = jwt_decode<any>(`${t}`);
  const isExpired = Date.now() >= exp * 1000;
  if (!isExpired) {
    if (req && req.headers) {
      req.headers.Authorization = `Bearer ${t}`;
    }
    return req;
  }
  // refresh token
  await TokenService.refreshToken();

  // getting refreshed token
  t = localStorage.getItem('token');

  // update current request header
  if (req && req.headers) {
    req.headers.Authorization = `Bearer ${t}`;
  }

  return req;
});

// handling of token corruption
// in case of 401 - forward to login
DefaultAPI.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;
    const status = error.response.status;
    if (status === 401) {
      if (!originalConfig._retry) {
        originalConfig._retry = true;
        try {
          const nt = await TokenService.refreshToken();
          originalConfig.headers.Authorization = `Bearer ${nt.token}`;
          return DefaultAPI(originalConfig);
        } catch (_error: any) {
          console.warn('cannot resend request');
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }
          return Promise.reject(_error);
        }
      } else {
        console.warn('401 error');
        localStorage.removeItem('token');
        localStorage.removeItem('refresh_token');
        location.replace(`${location.origin}/login`);
      }
    }
    return Promise.reject(error);
  }
);

export { DefaultAPI, LoginAPI, defaultConfig, loginConfig };
