import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/tube.png'


const _hoisted_1 = { class: "sidebar__inner" }
const _hoisted_2 = { class: "header-container" }
const _hoisted_3 = { class: "version" }
const _hoisted_4 = { class: "languages" }
const _hoisted_5 = { class: "languages-form" }
const _hoisted_6 = { class: "languages-form__input-wrapper" }
const _hoisted_7 = { class: "languages-form__input-wrapper" }
const _hoisted_8 = { class: "languages-form__input-wrapper" }
const _hoisted_9 = { class: "menu-sector" }
const _hoisted_10 = { class: "menu-sector" }
const _hoisted_11 = { class: "menu-sector" }
const _hoisted_12 = { class: "menu-sector" }
const _hoisted_13 = { class: "menu-sector" }
const _hoisted_14 = { class: "menu-sector" }
const _hoisted_15 = { class: "menu-sector" }
const _hoisted_16 = { class: "menu-sector" }
const _hoisted_17 = { class: "menu-sector" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Link = _resolveComponent("Link")!
  const _component_Button = _resolveComponent("Button")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["sidebar", { collapsed: _ctx.isCollapsed }])
    }, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("img", {
            class: "image",
            src: _imports_0,
            alt: "small tube",
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onLogoClick && _ctx.onLogoClick(...args)))
          }),
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.version), 1),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("form", _hoisted_5, [
              _createElementVNode("fieldset", null, [
                _cache[8] || (_cache[8] = _createElementVNode("legend", null, "Language:", -1)),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("input", {
                    type: "radio",
                    id: "lang-en",
                    name: "language",
                    value: "en",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onLanguageClick && _ctx.onLanguageClick(...args)))
                  }),
                  _cache[5] || (_cache[5] = _createElementVNode("label", { for: "lang-en" }, "EN", -1))
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("input", {
                    type: "radio",
                    id: "lang-es",
                    name: "language",
                    value: "es",
                    onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onLanguageClick && _ctx.onLanguageClick(...args)))
                  }),
                  _cache[6] || (_cache[6] = _createElementVNode("label", { for: "lang-es" }, "ES", -1))
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("input", {
                    type: "radio",
                    id: "lang-pt",
                    name: "language",
                    value: "pt",
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onLanguageClick && _ctx.onLanguageClick(...args)))
                  }),
                  _cache[7] || (_cache[7] = _createElementVNode("label", { for: "lang-pt" }, "PT", -1))
                ])
              ])
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _cache[13] || (_cache[13] = _createElementVNode("p", null, [
            _createElementVNode("strong", null, "Products")
          ], -1)),
          _createVNode(_component_Link, {
            to: "All products",
            params: { pageId: 1 },
            alt: ['/products/all']
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode(" All Products ")
            ])),
            _: 1
          }),
          _createVNode(_component_Link, {
            to: "New products",
            params: { pageId: 1 },
            alt: ['/products/new']
          }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createTextVNode(" Product Requests ")
            ])),
            _: 1
          }),
          _createVNode(_component_Link, {
            to: "Mystery products",
            alt: ['/products/mystery']
          }, {
            default: _withCtx(() => _cache[11] || (_cache[11] = [
              _createTextVNode(" Mystery Products ")
            ])),
            _: 1
          }),
          _createVNode(_component_Link, {
            to: "History",
            params: { pageId: 1 },
            alt: ['/history']
          }, {
            default: _withCtx(() => _cache[12] || (_cache[12] = [
              _createTextVNode(" History ")
            ])),
            _: 1
          })
        ]),
        _cache[32] || (_cache[32] = _createElementVNode("div", { class: "delimiter" }, null, -1)),
        _createElementVNode("div", _hoisted_10, [
          _cache[17] || (_cache[17] = _createElementVNode("p", null, [
            _createElementVNode("strong", null, "Ingredients")
          ], -1)),
          _createVNode(_component_Link, {
            to: "All ingredients",
            alt: ['/ingredients/all'],
            params: { pageId: 1 }
          }, {
            default: _withCtx(() => _cache[14] || (_cache[14] = [
              _createTextVNode(" All Ingredients ")
            ])),
            _: 1
          }),
          _createVNode(_component_Link, {
            to: "New ingredients",
            alt: ['/ingredients/new'],
            params: { pageId: 1 }
          }, {
            default: _withCtx(() => _cache[15] || (_cache[15] = [
              _createTextVNode(" New Ingredients ")
            ])),
            _: 1
          }),
          _createVNode(_component_Link, {
            to: "Ingredients match",
            alt: ['/ingredients/match'],
            params: { pageId: 1 }
          }, {
            default: _withCtx(() => _cache[16] || (_cache[16] = [
              _createTextVNode(" Ingredient match ")
            ])),
            _: 1
          })
        ]),
        _cache[33] || (_cache[33] = _createElementVNode("div", { class: "delimiter" }, null, -1)),
        _createElementVNode("div", _hoisted_11, [
          _cache[20] || (_cache[20] = _createElementVNode("p", null, [
            _createElementVNode("strong", null, "Brands")
          ], -1)),
          _createVNode(_component_Link, {
            to: "Brands",
            alt: ['/brands/all'],
            params: { pageId: 1 }
          }, {
            default: _withCtx(() => _cache[18] || (_cache[18] = [
              _createTextVNode(" All Brands ")
            ])),
            _: 1
          }),
          _createVNode(_component_Link, {
            to: "New brands",
            alt: ['/brands/new'],
            params: { pageId: 1 }
          }, {
            default: _withCtx(() => _cache[19] || (_cache[19] = [
              _createTextVNode(" New brands ")
            ])),
            _: 1
          })
        ]),
        _cache[34] || (_cache[34] = _createElementVNode("div", { class: "delimiter" }, null, -1)),
        _createElementVNode("div", _hoisted_12, [
          _cache[25] || (_cache[25] = _createElementVNode("p", null, [
            _createElementVNode("strong", null, "Reviews")
          ], -1)),
          _createVNode(_component_Link, {
            to: "All reviewers",
            alt: ['/reviewers'],
            params: { pageId: 1 }
          }, {
            default: _withCtx(() => _cache[21] || (_cache[21] = [
              _createTextVNode(" Reviewers ")
            ])),
            _: 1
          }),
          _createVNode(_component_Link, {
            to: "All collections",
            alt: ['/collections'],
            params: { pageId: 1 }
          }, {
            default: _withCtx(() => _cache[22] || (_cache[22] = [
              _createTextVNode(" Collections ")
            ])),
            _: 1
          }),
          _createVNode(_component_Link, {
            to: "All review suggestions",
            alt: ['/reviews/suggestions/'],
            params: { pageId: 1 }
          }, {
            default: _withCtx(() => _cache[23] || (_cache[23] = [
              _createTextVNode(" Review Suggestions ")
            ])),
            _: 1
          }),
          _createVNode(_component_Link, {
            to: "All user reviews",
            alt: ['/user-reviews'],
            params: { pageId: 1 }
          }, {
            default: _withCtx(() => _cache[24] || (_cache[24] = [
              _createTextVNode(" User reviews ")
            ])),
            _: 1
          })
        ]),
        _cache[35] || (_cache[35] = _createElementVNode("div", { class: "delimiter" }, null, -1)),
        _createElementVNode("div", _hoisted_13, [
          _cache[27] || (_cache[27] = _createElementVNode("p", null, [
            _createElementVNode("strong", null, "Services")
          ], -1)),
          _createVNode(_component_Link, {
            to: "All notifications",
            alt: ['/notifications'],
            params: { pageId: 1 }
          }, {
            default: _withCtx(() => _cache[26] || (_cache[26] = [
              _createTextVNode(" Notifications ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createVNode(_component_Link, {
            to: "HairLab",
            alt: ['/hairlab'],
            params: { pageId: 1 }
          }, {
            default: _withCtx(() => _cache[28] || (_cache[28] = [
              _createTextVNode(" Non Shampoo Scans ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_15, [
          _createVNode(_component_Link, {
            to: "All mistakes",
            alt: ['/mistakes'],
            params: { pageId: 1 }
          }, {
            default: _withCtx(() => _cache[29] || (_cache[29] = [
              _createTextVNode(" Mistakes ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createVNode(_component_Link, {
            to: "Images logs",
            alt: ['/images'],
            params: { pageId: 1 }
          }, {
            default: _withCtx(() => _cache[30] || (_cache[30] = [
              _createTextVNode(" Search logs ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createVNode(_component_Link, {
            to: "All users",
            alt: ['/users'],
            params: { pageId: 1 }
          }, {
            default: _withCtx(() => _cache[31] || (_cache[31] = [
              _createTextVNode(" Users ")
            ])),
            _: 1
          })
        ]),
        _createVNode(_component_Button, {
          icon: "pi pi-unlock",
          label: "Logout",
          class: "p-button-rounded p-button-text p-button-logout",
          onClick: _ctx.onConfirmLogout
        }, null, 8, ["onClick"])
      ]),
      _cache[36] || (_cache[36] = _createElementVNode("div", { class: "bg" }, null, -1))
    ], 2),
    _createElementVNode("div", {
      class: "expand",
      onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.onExpand && _ctx.onExpand(...args)))
    }, [
      _createElementVNode("i", {
        class: _normalizeClass(["pi", {
        'pi-angle-double-right': _ctx.isCollapsed,
        'pi-angle-double-left': !_ctx.isCollapsed,
      }]),
        style: {"font-size":"90%"}
      }, null, 2)
    ]),
    _createVNode(_component_ConfirmDialog)
  ], 64))
}