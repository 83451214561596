import { DefaultAPI } from '@/api';
import { useAxios } from '@vueuse/integrations/useAxios';
import { FetchParams, LocalizationsResponse } from '@/types/ILocalizations';

const URL = '/localisation';

export default {
  getLocalizations: ({
    domain,
    language,
    full_data,
    immediate = true,
  }: FetchParams) => {
    return useAxios<LocalizationsResponse>(
      URL,
      {
        method: 'GET',
        params: { domain, language, full_data },
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },

  putLocalizations: ({
    po_content,
    domain,
    language,
    full_reload,
    immediate = true,
  }: FetchParams) => {
    return useAxios(
      URL,
      {
        method: 'PUT',
        data: { po_content, domain, language, full_reload },
      },
      DefaultAPI,
      {
        immediate,
      }
    );
  },
};
