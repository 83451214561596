import { auth } from '@/store/modules/auth';
import { filter } from '@/store/modules/filter';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import { CacheItem } from '@/types/ILocalizations';

const store: StoreOptions<RootState> = {
  state: {
    helloMessage: 'Hello',
    contentLanguage: undefined,
    isEnContentLanguage: undefined,
    localizationQueryCache: [],
  },
  getters: {},
  mutations: {
    setContentLanguage(state, payload: string) {
      state.contentLanguage = payload;
      payload === 'en'
        ? (state.isEnContentLanguage = true)
        : (state.isEnContentLanguage = false);
    },
    cacheQueryResult(state, payload: CacheItem) {
      state.localizationQueryCache.push(payload);
    },
  },
  actions: {
    changeContentLanguage({ commit }, payload) {
      commit('setContentLanguage', payload);
      localStorage.setItem(
        `language-onskin-admin_${process.env.VUE_APP_MODE}`,
        payload
      );
    },
    cacheLocalizationQuery({ commit }, payload) {
      commit('cacheQueryResult', payload);
    },
  },
  modules: { auth, filter },
};

export default new Vuex.Store<RootState>(store);
