<template>
  <div class="sidebar" :class="{ collapsed: isCollapsed }">
    <div class="sidebar__inner">
      <div class="header-container">
        <img
          class="image"
          src="@/assets/images/tube.png"
          alt="small tube"
          @click="onLogoClick"
        />
        <div class="version">{{ version }}</div>
        <div class="languages">
          <form class="languages-form">
            <fieldset>
              <legend>Language:</legend>
              <div class="languages-form__input-wrapper">
                <input
                  type="radio"
                  id="lang-en"
                  name="language"
                  value="en"
                  @click="onLanguageClick"
                />
                <label for="lang-en">EN</label>
              </div>
              <div class="languages-form__input-wrapper">
                <input
                  type="radio"
                  id="lang-es"
                  name="language"
                  value="es"
                  @click="onLanguageClick"
                />
                <label for="lang-es">ES</label>
              </div>
              <div class="languages-form__input-wrapper">
                <input
                  type="radio"
                  id="lang-pt"
                  name="language"
                  value="pt"
                  @click="onLanguageClick"
                />
                <label for="lang-pt">PT</label>
              </div>
            </fieldset>
          </form>
        </div>
      </div>
      <div class="menu-sector">
        <p><strong>Products</strong></p>
        <Link
          to="All products"
          :params="{ pageId: 1 }"
          :alt="['/products/all']"
        >
          All Products
        </Link>
        <Link
          to="New products"
          :params="{ pageId: 1 }"
          :alt="['/products/new']"
        >
          Product Requests
        </Link>
        <Link to="Mystery products" :alt="['/products/mystery']">
          Mystery Products
        </Link>
        <Link to="History" :params="{ pageId: 1 }" :alt="['/history']">
          History
        </Link>
      </div>
      <div class="delimiter"></div>

      <div class="menu-sector">
        <p><strong>Ingredients</strong></p>
        <Link
          to="All ingredients"
          :alt="['/ingredients/all']"
          :params="{ pageId: 1 }"
        >
          All Ingredients
        </Link>
        <Link
          to="New ingredients"
          :alt="['/ingredients/new']"
          :params="{ pageId: 1 }"
        >
          New Ingredients
        </Link>
        <Link
          to="Ingredients match"
          :alt="['/ingredients/match']"
          :params="{ pageId: 1 }"
        >
          Ingredient match
        </Link>
      </div>

      <div class="delimiter"></div>
      <div class="menu-sector">
        <p><strong>Brands</strong></p>
        <Link to="Brands" :alt="['/brands/all']" :params="{ pageId: 1 }">
          All Brands
        </Link>
        <Link to="New brands" :alt="['/brands/new']" :params="{ pageId: 1 }">
          New brands
        </Link>
      </div>
      <div class="delimiter"></div>

      <div class="menu-sector">
        <p><strong>Reviews</strong></p>
        <Link to="All reviewers" :alt="['/reviewers']" :params="{ pageId: 1 }">
          Reviewers
        </Link>
        <Link
          to="All collections"
          :alt="['/collections']"
          :params="{ pageId: 1 }"
        >
          Collections
        </Link>
        <Link
          to="All review suggestions"
          :alt="['/reviews/suggestions/']"
          :params="{ pageId: 1 }"
        >
          Review Suggestions
        </Link>
        <Link
          to="All user reviews"
          :alt="['/user-reviews']"
          :params="{ pageId: 1 }"
        >
          User reviews
        </Link>
      </div>

      <div class="delimiter"></div>
      <div class="menu-sector">
        <p><strong>Services</strong></p>
        <Link
          to="All notifications"
          :alt="['/notifications']"
          :params="{ pageId: 1 }"
        >
          Notifications
        </Link>
      </div>

      <div class="menu-sector">
        <Link to="HairLab" :alt="['/hairlab']" :params="{ pageId: 1 }">
          Non Shampoo Scans
        </Link>
      </div>

      <div class="menu-sector">
        <Link to="All mistakes" :alt="['/mistakes']" :params="{ pageId: 1 }">
          Mistakes
        </Link>
      </div>

      <div class="menu-sector">
        <Link to="Images logs" :alt="['/images']" :params="{ pageId: 1 }">
          Search logs
        </Link>
      </div>

      <div class="menu-sector">
        <Link to="All users" :alt="['/users']" :params="{ pageId: 1 }">
          Users
        </Link>
      </div>

      <Button
        icon="pi pi-unlock"
        label="Logout"
        class="p-button-rounded p-button-text p-button-logout"
        @click="onConfirmLogout"
      />
    </div>
    <div class="bg" />
  </div>
  <div class="expand" @click="onExpand">
    <i
      class="pi"
      :class="{
        'pi-angle-double-right': isCollapsed,
        'pi-angle-double-left': !isCollapsed,
      }"
      style="font-size: 90%"
    />
  </div>
  <ConfirmDialog />
</template>

<script lang="ts">
import { AuthActions } from '@/store/modules/auth/actions';
import Button from 'primevue/button';
import ConfirmDialog from 'primevue/confirmdialog';
import { useConfirm } from 'primevue/useconfirm';
import { defineComponent, onMounted, ref, nextTick } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import Link from './Link.vue';

export default defineComponent({
  name: 'SideBar',
  components: { Link, ConfirmDialog, Button },
  setup() {
    const version = ref(process.env.VUE_APP_VERSION);
    const router = useRouter();
    const store = useStore();
    const confirm = useConfirm();
    const isCollapsed = ref(false);

    const onExpand = () => {
      isCollapsed.value = !isCollapsed.value;
      localStorage.setItem(
        'settings',
        JSON.stringify({ sidebarCollapsed: isCollapsed.value })
      );
    };

    const onLogoClick = async () => {
      await router.push('/products/all/1');
    };

    const onConfirmLogout = async () => {
      confirm.require({
        message: 'Are you sure to proceed?',
        header: 'Logout',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          logout();
        },
      });
    };

    const onLanguageClick = async (e: Event) => {
      await store.dispatch(
        'changeContentLanguage',
        e.target instanceof HTMLInputElement && e.target.value
      );
    };

    const logout = async () => {
      await store.dispatch(`auth/${AuthActions.LOGOUT_USER}`);
      router.push({ name: 'login' });
    };

    const localStorageLanguage = localStorage.getItem(
      `language-onskin-admin_${process.env.VUE_APP_MODE}`
    );

    onMounted(async () => {
      if (localStorageLanguage) {
        await store.dispatch('changeContentLanguage', localStorageLanguage);
      } else await store.dispatch('changeContentLanguage', 'en');

      nextTick(() => {
        document
          .getElementById(`lang-${store.state.contentLanguage}`)
          ?.setAttribute('checked', 'true');
      });
      const userSettings = JSON.parse(
        localStorage.getItem('settings') || '{"sidebarCollapsed":false}'
      );
      isCollapsed.value = userSettings.sidebarCollapsed;
    });

    return {
      onLogoClick,
      onLanguageClick,
      isCollapsed,
      onExpand,
      version,
      onConfirmLogout,
      logout,
    };
  },
});
</script>

<style lang="scss" scoped>
.header-container {
  height: $header-height;
  min-height: $header-height;
  display: flex;
  align-items: center;
}
.languages {
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 15px;
  height: 100%;

  .languages-form {
    width: fit-content;
  }

  fieldset {
    padding: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    column-gap: 10px;
    margin: 0;
  }

  legend {
    font-size: 14px;
    text-align: center;
  }

  .languages-form__input-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  input {
    margin: 5px;
    margin-left: 0;
    cursor: pointer;
  }

  label {
    position: relative;
    cursor: pointer;
    font-size: 16px;
  }

  input[type='radio'] {
    appearance: none;
    background: $light-green;
    border: 1.5px solid $dark-green;
    height: 1.2em;
    width: 1.2em;
    border-radius: 100%;
    vertical-align: text-bottom;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      margin: auto;
      width: 70%;
      height: 70%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      overflow: hidden;
    }

    &:checked::before {
      border: 5px solid transparent;
      border-radius: 100%;
      background: $dark-green;
    }
  }
}
.image {
  margin-right: 5px;
  border-radius: 6px;
  width: 61px;
  height: 61px;
  cursor: pointer;
}
.sidebar {
  display: flex;
  flex-direction: column;
  min-width: 315px;
  padding: 0;
  border-right: 1px solid $border-green;
  height: 100%;
  min-height: 100vh;
  position: sticky;
  background: $light-green;
  left: 0;
  top: 0;
  transition: width, min-width 0.2s;
  overflow: hidden;
  transform: translate(0, 0);
  &.collapsed {
    width: 3px;
    min-width: 3px;
    padding: 0;
    z-index: 1;
    min-height: 100vh;
    & + .expand {
      width: 50px;
    }
    &:hover {
      min-width: 315px;
      padding: 0 0px;
    }
  }
  &:not(.collapsed) {
    .sidebar__inner {
      padding: 0px 20px 20px;
      min-width: 265px;
    }
  }
  &__inner {
    transition: width, min-width 0.2s;
    display: flex;
    position: relative;
    flex-direction: column;
    min-width: 315px;
    padding: 0px 20px 20px;
    overflow: auto;
    height: calc(100vh - 60px);
    &::-webkit-scrollbar {
      width: 5px;
      background-color: $medium-green;
      transition: all 0.1s;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $light-green-1;
      &:hover {
        background-color: $dark-green;
      }
    }
  }
}
.expand {
  width: 60px;
  height: 40px;
  bottom: 10px;
  left: 20px;
  cursor: pointer;
  transition: all 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: $grey-green;
  z-index: 2;
  position: fixed;
  border: 1px solid $light-green-1;
  &:hover {
    background: $grey-green-2;
  }
}

.delimiter {
  border-top: 1px solid $border-green;
  height: 1px;
  margin: 15px 0;
}
::v-deep(button.p-button) {
  &.p-button-text {
    @extend .button-common;
    margin: auto auto 0;
    background: $grey-green;
    border-color: $light-green-1;
    color: $black;
    height: 40px;
    min-height: 40px;
    .p-button-label {
      text-align: left;
      transition: all 0s;
      font-weight: 500;
    }
    &:hover {
      background: $medium-green;
      border-color: $light-green-1;
      color: $black;
      span {
        color: $black;
      }
    }
  }
  &.p-button-logout {
    position: fixed;
    bottom: 10px;
    z-index: 2;
    left: 180px;
  }
}
.bg {
  content: '';
  display: block;
  height: 60px;
  width: 100%;
  position: sticky;
  bottom: 0;
  left: 0;
  background: $light-green;
  border-top: 1px solid $border-green;
  z-index: 0;
}
.version {
  padding-left: 5px;
}

.link {
  @extend .button-common;
  display: flex;
  align-items: center;
  padding: 0 18px;
  margin-bottom: 6px;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.1s;
  color: $black;
  background: $grey-green;
  border-color: $light-green-1;
  letter-spacing: -0.11px;
  font-weight: bold;
  // border-radius: 15px;
  &:hover {
    background: $medium-green;
    .sidebar__notification {
      color: $white;
    }
  }
  &.active {
    color: $white;
    background: $dark-green;
    border: 1px solid $dark-green;
    .sidebar__notification {
      color: $dark-green;
      background: $white;
    }
  }
  .sidebar__notification {
    color: $white;
    background: $green-2;
    font-size: 12px;
    font-weight: 500;
    line-height: 26px;
    padding: 0 10px;
    border-radius: 14px;
    margin-left: auto;
  }
}
</style>
